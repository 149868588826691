<template>
  <div class="h-d-flex h-align-item-center">
    <select
      class="h-input h-select-no-border"
      v-model="selectedDateRange"
      @change="setDateRange($event.target.value)">
      <option value="7">Last 7 days</option>
      <option value="30">Last 30 days</option>
      <option value="365">Last year</option>
      <option value="5000">All-time</option>
    </select>
    <select
      id="prioritySelect"
      class="h-input"
      v-model="selectedPriority"
      @change="setPriority($event.target.value)">
      <option value="any">All priority</option>
      <option v-for="p in priorities" :value="p" :key="p">
        {{ p }}
      </option>
    </select>
    <select
      id="statsSelect"
      class="h-input"
      v-model="alertStatus"
      @change="setAlertStatus($event.target.value)">
      <option value="any">All status</option>
      <option value="active">Open</option>
      <option value="wont_fix">Ignored</option>
      <option value="resolved">Resolved</option>
    </select>

    <select
      id="selectCategory"
      class="h-input"
      v-model="selectedCategoryOrTopic"
      @change="setCombinedFilter($event.target.value)">
      <option value="All category">All category</option>
      <option v-for="category in categories" :value="category" :key="category">
        {{ category }}
      </option>
      <option value="Oximeter readings">Oximeter readings alerts</option>
      <option value="plan end date">Plan end date</option>
      <option value="steps">Steps reading</option>
    </select>
  </div>
</template>
<script>
import moment from 'moment-timezone';
import settings from '@/settings.js';

export default {
  data() {
    return {
      automaticNudging: false,
      selectedDateRange: '365',
      alertStatus: 'active',
      selectedCategoryOrTopic: 'Follow-up Required',
      selectedPriority: 'any',
      categories: null,
      priorities: null,
      dateRange: {
        start: moment()
          .subtract(365, 'days')
          .format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      },
    };
  },
  async mounted() {
    const response = await this.$api.getClientAlertsCategories();
    this.categories = response.categories;
    this.priorities = ['critical', 'high', 'medium', 'low', 'info'];
  },
  methods: {
    resetSelects() {
      this.selectedDateRange = '365';
      this.alertStatus = 'any';
      this.selectedCategoryOrTopic = 'All category';
      this.selectedPriority = 'any';
    },
    applyFilterSettings() {
      this.$emit('applyFilters', {
        dateStart: this.dateRange.start,
        dateEnd: this.dateRange.end,
        alertStatus: this.alertStatus === 'any' ? '' : this.alertStatus,
        priority: this.selectedPriority === 'any' ? '' : this.selectedPriority,
        category: this.selectedCategoryOrTopic === 'All category' ? '' : this.category,
        topic: this.selectedCategoryOrTopic === 'All category' ? '' : this.topic,
      });
    },
    resetFilters() {
      this.category = '';
      this.topic = '';
      this.applyFilterSettings();
    },
    setDateRange() {
      const days = parseInt(this.selectedDateRange, 10);
      this.dateRange.start = moment()
        .subtract(days, 'days')
        .format();
      this.setCombinedFilter();
      this.applyFilterSettings();
    },
    setPriority() {
      this.setCombinedFilter();
      this.applyFilterSettings();
    },
    setAlertStatus() {
      this.setCombinedFilter();
      this.applyFilterSettings();
    },
    setCombinedFilter() {
      const value = this.selectedCategoryOrTopic;
      console.log(value, '');
      if (value === 'All category') {
        this.category = '';
        this.topic = '';
      } else if (this.categories.includes(value)) {
        this.category = value;
        this.topic = '';
      } else {
        this.topic = value;
        this.category = '';
      }

      this.applyFilterSettings();
    },
  },
  watch: {
    resetSignal(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.resetSelects();
      }
    },
  },
  props: {
    initialFilters: Object,
    resetSignal: Boolean,
  },
};
</script>
