<template>
  <Drawer
    class="h-drawer-max60"
    :direction="'right'"
    :exist="true"
    ref="HealthStatusListEditDialog">
    <div class="card h-drawer-card h-health-status-drawer h-drawer-scroll h-h100">
      <div class="h-drawer-card-header h-border-0">
        <div class="h-main-drawer-card-header h-margin-bottom-l">
          <h3 class="h-h3 h-primary_shade_1">Health Status Items</h3>
          <button type="button" class="close" aria-hidden="true" @click="close" :disabled="loading">
            <img
              data-tooltip="Close"
              src="../../../public/assets/images/Collapse-drawer.svg"
              alt="" />
          </button>
        </div>
      </div>
      <div class="card-body h-drawer-card-body h-padding-0">
        <div v-if="!loading" class="h1-table h-health-status-table ">
          <table
            class="table dataTable h-no-sort h-text-left h-padding-last"
            style="display: table">
            <thead class="thead-light">
              <tr>
                <th class="table-title-bold">On</th>
                <th class="table-title-bold">Topic</th>
                <th class="table-title-bold h-text-left">Target Range</th>
                <th class="table-title-bold h-text-left">Unit</th>
                <th class="table-title-bold h-text-left">Is mandatory</th>
                <th class="table-title-bold h-text-left">CPET Factor</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="item of filteredItems" :key="item.title">
                <td>
                  <div class="h-on-off-input custom-control custom-switch switch-success ">
                    <div class="h-checkbox h-cursor-pointer">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        :id="`healthChecklistStatusItem-${item.title}`"
                        :ref="`healthChecklistStatusItem-${item.title}`"
                        :checked="item.is_active"
                        @change="handleActiveChange(item, $event)" />
                      <label
                        :data-tooltip="item.is_active
                          ? `Check to turn off '${item.title}'`
                          : `Check to turn on '${item.title}'`
                          "
                        class="custom-control-label"
                        :for="`healthChecklistStatusItem-${item.title}`"></label>
                    </div>
                  </div>
                </td>
                <td>
                  {{ item.title }}
                </td>
                <td>
                  <nobr class="h-input-range">
                    <input
                      type="number"
                      class="form-control target-input"
                      v-model="item.target.value_1" />
                    <input
                      type="number"
                      class="form-control target-input"
                      v-model="item.target.value_2" />
                  </nobr>
                </td>
                <td>
                  {{ item.unit }}
                </td>
                <td class="text-left">
                  <div class="custom-control custom-switch switch-success">
                    <label
                      class="h-toggle"
                      :for="`healthChecklistStatusItem-isMandatory-${item.title}`">
                      <input
                        type="checkbox"
                        class="h-input"
                        :disabled="!item.is_active"
                        :id="`healthChecklistStatusItem-isMandatory-${item.title}`"
                        v-model="item.is_mandatory" />
                      <span
                        :data-tooltip="item.is_mandatory
      ? `Toggle off to make '${item.title}' not mandatory`
      : `Toggle on to make '${item.title}' mandatory`
      "
                        class="h-slider"></span>
                    </label>
                  </div>
                </td>
                <td class="text-left">
                  <div class="custom-control custom-switch switch-success">
                    <label
                      class="h-toggle"
                      :for="`healthChecklistStatusItem-is_traced-${item.title}`">
                      <input
                        type="checkbox"
                        class="h-input"
                        :id="`healthChecklistStatusItem-is_traced-${item.title}`"
                        :disabled="!item.is_active"
                        v-model="item.is_traced" />
                      <span
                        :data-tooltip="item.is_traced
      ? `Toggle off CPET Factor`
      : `Toggle on CPET Factor`
      "
                        class="h-slider"></span>
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <Loader v-else />
      </div>

      <div class="card-footer h-drawer-card-footer">
        <div class="h-row">
          <button type="button" class="h-btn h-btn-accent" @click="save" :disabled="loading">
            Update
          </button>

          <button
            type="button"
            class="h-btn h-btn-dangerous h-margin-left-s"
            @click="close"
            :disabled="loading">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </Drawer>
</template>

<style scoped lang="stylus">
.table-title-bold {
  font-family: Quicksand;
  font-weight: 900;
  font-size: 15px;
  color: #152145;
  letter-spacing: 18;
}
</style>

<script>
import Vue from 'vue';
import Loader from '@/components/Loader';
import settings from '@/settings.js';

export default {
  name: 'HealthStatusListEditDialog',
  props: ['holderId', 'cpMode', 'items'],

  data() {
    return {
      loading: false,
      editedItems: [],
      twoMetricValues: settings.twoMetricValues,
      autoCheckedFlags: {},
    };
  },
  computed: {
    filteredItems() {
      return this.editedItems
        .filter(item => !item.is_custom && !this.shouldHide(item.title))
        .sort((a, b) => a.title.localeCompare(b.title));
    }
  },

  methods: {
    handleActiveChange(item, event) {
      const isActive = event.target.checked;
      Vue.set(item, 'is_active', isActive);
      if (isActive) {
        Vue.set(item, 'is_mandatory', true);
      }
      if (!isActive) {
        Vue.set(item, 'is_mandatory', false);
      }
    },
    shouldHide(title) {
      const hideTitles = [
        'How many cigarettes',
        'How many alcoholic',
        'Foot Temperature',
        'Total water intake',
        'Breathing Difficulty',
        'Nutrition',
        'Wheals',
      ];
      return hideTitles.some(substring => title.includes(substring));
    },
    hfiItem(title) {
      const hfiItemsTitles = [
        'Your Weight',
        'Pain Score',
        'Time spent on physical activity',
        'Hours of sleep',
        'Quality of sleep',
        'Steps Count',
      ];
      return hfiItemsTitles.some(substring => title.includes(substring));
    },

    show() {
      this.$refs.HealthStatusListEditDialog.open();
      this.$refs.HealthStatusListEditDialog.active = true;
      this.loading = false;
      this.editedItems = JSON.parse(JSON.stringify(this.items));

      this.editedItems.forEach((item) => {
        console.log("item", item)
        if (!item.target || typeof item.target !== 'object') {
          item.target = { value_1: 0, value_2: 0 };
        }
        if (item.target.value_1 === undefined) {
          item.target.value_1 = 0;
        }
        if (item.target.value_2 === undefined) {
          item.target.value_2 = 0;
        }
        if (this.shouldHide(item.title) || item.is_custom) {
          item.is_active = false;
        }
      });
    },

    close(force) {
      if (!force && this.loading) return;
      this.$refs.HealthStatusListEditDialog.close();
      this.$refs.HealthStatusListEditDialog.active = false;
    },

    async save() {
      if (this.cpMode) {
        Vue.$confirm({
          title: 'Update',
          message: 'Should this change be applied to every patient on this care protocol?',
          button: {
            yes: 'Yes',
            no: 'No',
          },
          callback: async (confirm) => {
            await this.updateCheckListElements(confirm);
          },
        });
      } else {
        await this.updateCheckListElements(false);
      }
    },
    async updateCheckListElements(allPatientsChange) {
      this.loading = true;
      const payload = {
        cpMode: this.cpMode,
        holderId: this.holderId,
        applyToAllPatients: allPatientsChange,
        items: this.editedItems.map((item) => {
          return {
            id: item.id,
            title: item.title,
            end_date: item.end_date,
            is_active: item.is_active,
            is_mandatory: item.is_mandatory,
            is_traced: item.is_traced,
            target: {
              value_1: Number(item.target.value_1) ?? 0,
              value_2: Number(item.target.value_2) ?? 0,
            }
          };
        }),
      };

      console.log("payload", payload)
      const response = await this.$api.updateClientChecklistItem(payload);
      this.$emit('itemsUpdated', response.checklist);
      this.close(true);
    },
  },
  mounted() {

  },
  components: {
    Loader,
  },
};
</script>
